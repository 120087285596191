/*! Configuration for the Playground */
@use "./foundation/var" as *;

#interactive-playground {
	flex-direction: row;

	#interactive-code-editor {
		margin: 0;
		display: flex;
		flex-grow: 2;

		#playground-option-menu {
			display: flex;
			flex-direction: row;
			align-content: center;
			justify-content: flex-start;
			border-bottom: 2px solid $scheme-gray;

			#clear-content-list-item {
				margin-right: 1rem;
			}

			#version-selection {
				margin-bottom: 0;
				margin-left: 0;
				position: relative;
				z-index: 2;

				& > #versions-dropdown {
					/* Hide the dropdown at first */
					display: none;
					visibility: hidden;
					position: absolute;
					top: 100%;
					width: calc(100% - 8px);
					margin: 4px 0 0 4px;
					z-index: 2;
					overflow-y: auto;
					max-height: 60vh;
					border-radius: 5px;
					box-shadow: rgba(0, 0, 0, 0.35) 0 4px 12px;

					&:last-child.version-selector-button-wrapper {
						/* Remove the border from the last element */
						border-bottom: none;
					}

					li.version-selector-button-wrapper {
						display: flex;
						align-content: center;
						justify-content: center;
						border-bottom: 2px solid $scheme-gray;

						button {
							/* Similar styles like in the playground button */
							padding: 0.8rem 0.8rem;
							background-color: white;
							border: none;
							cursor: pointer;

							/* Make the button grow to the size of the parent */
							flex-grow: 1;

							&:hover {
								color: $scheme-primary;
								background-color: $scheme-gray;
							}
						}
					}
				}

				#version-selector-root-button-wrapper {
					/* A minimum width to prevent the button from being too small with short versions */
					min-width: 5rem;
					display: flex;

					button {
						flex-grow: 1;
						display: flex;
						flex-direction: row;
						align-content: center;
						justify-content: center;
						column-gap: 0.4rem;

						.fa-caret-down::before {
							display: block;
							margin-top: 0.7em;
						}
					}
				}
			}
		}

		/* The entire code entire window -> parent of the label and textarea */
		#code-editor {
			display: flex;
			flex-grow: 0;
			min-width: 0;
			min-height: 0;

			& > #phone-text-saving-state {
				/* Phone only saving state div */
				display: none;
				visibility: hidden;
			}

			.line-numbers .line-numbers-rows {
				/* Disable the border for the line-numbers sidebar */
				border-right: none;

				/* Remove the additional spacing which was used for the border */
				left: -2.8em;
				width: 2em;
			}

			/*
       * The user input fields - the two fields will be put over each other to make it look
       * like a single field
      */
			#code-editor-textarea,
			#code-editor-highlighting-field {
				/* Putting them on top of each other */
				position: absolute;
				flex-grow: 0;

				/* Allow scrolling */
				overflow: auto;

				/* Identical styling */
				margin: 0;
				padding: 1rem 1rem 1rem 3rem;
				resize: none;
				border: none;
				outline: none;
				max-width: 65vw;

				/* Remove code area border radius */
				border-radius: 0;
			}

			#code-editor-textarea,
			#code-editor-highlighting-field,
			#code-editor-highlighting-field * {
				/* Also add text styles to highlighting tokens */
				font-size: 14px;
				font-family: consolas, monospace;
				line-height: 18px;
				background-color: white;
				tab-size: 2;
			}

			#code-editor-textarea {
				/* Move the textarea in front of the result */
				z-index: 1;
				color: transparent;
				background: transparent;
				caret-color: $scheme-primary;

				&::selection {
					text-shadow: none;
					background: rgba(70, 93, 160, 0.2);
				}
			}

			#code-editor-highlighting-field {
				z-index: 0;

				& > #code-editor-highlighting-field-content {
					display: block; /* Due to issue where line numbers kept disappearing */
					margin: 0;
					padding: 0;
					border-radius: 0;
				}
			}
		}
	}

	.playground-button-wrapper {
		display: flex;
		align-content: center;
		justify-content: center;

		button {
			flex-grow: 1;
			padding: 1rem;
			background-color: white;
			border: none;
			cursor: pointer;

			&:hover {
				color: $scheme-primary;
				background-color: $scheme-gray;
			}
		}
	}

	/* Sidebar styling */
	#shell-sidebar {
		width: 35vw;
		min-width: 35vw;
		max-width: 35vw;
		margin: 0;
		display: flex;

		#shell-menu {
			padding: 0;
			width: inherit;

			.playground-button-wrapper {
				display: flex;
				flex-grow: 1;
			}

			button {
				border-bottom: 2px solid $scheme-gray;
				flex-grow: 1;
			}
		}

		#shell-output {
			& > .code-toolbar {
				color: #9efeff; /* Shades of purple - Console theme color */

				& pre,
				& code {
					border-radius: 0;
				}
			}
		}

		#shell-output {
			&,
			& > .code-toolbar,
			& > .code-toolbar > pre {
				display: flex;
				flex-grow: 1;
			}

			#shell-sidebar-highlight-field {
				background-color: $scheme-console-blue;
				display: flex;
				flex-grow: 1;
				margin: 0;
				padding: 0;
				width: inherit;

				/* Scroll on overflowing content - Hide if not necessary */
				overflow: auto;

				& > #shell-sidebar-highlight-field-content {
					border-radius: 0;
					align-self: flex-start;
					overflow: unset;

					/* Apply proper formatting */
					margin: 0;
					padding: 1rem;
					width: 30vw;
					min-height: 0;
					min-width: 0;
					flex-grow: 1;

					/* Update font */
					font-size: 14px;
					line-height: 18px;

					/* Tabs should be handled like spaces */
					tab-size: 1;
				}
			}
		}
	}
}

/* Phone overwrites */
@media screen and (max-width: $medium-screen-size) {
	#header-description-content {
		display: none;
		visibility: hidden;
	}

	#interactive-playground {
		flex-direction: column;
		width: 100vw;
		min-width: 100vw;
		max-width: 100vw;
		min-height: 90vh;

		#interactive-code-editor {
			min-height: calc(50vh + 6px);

			&,
			& > #code-editor {
				max-height: unset;
				min-width: 100vw;
				max-width: 100vw;
			}
		}

		#interactive-code-editor {
			& > #playground-option-menu {
				flex-grow: 0; /* Do not allow the menu to grow */
				overflow: visible;

				$height: 3rem;
				height: $height; // 3rem (48px)
				max-height: $height;
				min-height: $height;

				$width: 100vw;
				width: $width;
				max-width: $width;
				min-width: $width;

				margin-left: 0;
				margin-right: 0;
				padding: 0;

				& > #version-selection > #version-selector-root-button-wrapper,
				& > .playground-button-wrapper {
					& > button {
						padding: 0.8rem 0.4rem;
					}
				}

				p,
				button {
					white-space: nowrap;
				}

				& > #text-saving-state {
					display: none;
					visibility: hidden;
				}

				& > #version-selection > #version-selector-root-button-wrapper {
					height: 3rem;
					font-size: 0.9rem;

					& > button {
						/* Defining limits to avoid the text overflowing to a new line - Max space required is 10rem */
						max-width: 10rem;
						word-break: keep-all;
						padding: 0.95rem 0.6rem 0.8rem 0.6rem;

						& > span {
							display: inline-block;
							position: relative;
							direction: rtl;
							left: 0;
							top: 0;

							/* Ensure the overflow gets turned into a scrollbar */
							overflow-x: scroll;
							overflow-y: hidden;
							white-space: nowrap;

							/* Hide scrollbar for Chrome, Safari and Opera */
							&::-webkit-scrollbar {
								display: none;
							}

							/* Hide scrollbar for IE, Edge and Firefox */
							& {
								-ms-overflow-style: none; /* IE and Edge */
								scrollbar-width: none; /* Firefox */
							}
						}
					}
				}
			}

			& > #code-editor {
				flex-grow: 1;
				position: relative;

				#code-editor-textarea,
				#code-editor-highlighting-field {
					width: calc(100vw - 4rem);
					min-width: calc(100vw - 4rem);
					max-width: calc(100vw - 4rem);

					/* Init value, will be later modified by the playground resize handler */
					height: 40vh;
				}

				/* Phone-only saving state div */
				#phone-text-saving-state {
					display: flex;
					visibility: visible;
					position: absolute;
					align-content: center;
					justify-content: center;
					flex-direction: row;
					opacity: 1;
					top: 80%;
					right: 10%;
					z-index: 2;
					margin: 0;

					// Add a small shadow to the text to allow it to be visible on all backgrounds
					background-color: white;
					box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
					padding: 0.25rem 0.5rem;
					border-radius: 5px;

					&.fade-out {
						animation: fade-out 0.75s forwards;

						@keyframes fade-out {
							0% {
								opacity: 1;
							}
							100% {
								opacity: 0;
							}
						}
					}
				}
			}
		}

		#shell-sidebar {
			height: calc(40vh + 2px);
			min-height: calc(40vh + 2px);
			max-height: calc(40vh + 2px);
			width: 100vw;
			min-width: 100vw;
			max-width: 100vw;

			& > #shell-menu {
				height: 10vh;
				max-height: 3.5rem; // 3.5rem (56px)

				/* Small border at the top to more visually differentiate the areas */
				border-top: 2px solid $scheme-gray;
			}

			& > #shell-output #shell-sidebar-highlight-field {
				overflow: scroll;
				position: absolute;
				height: 100%;
				width: 100%;

				& > #shell-sidebar-highlight-field-content {
					height: calc(30vh - 2rem);
					min-height: calc(30vh - 2rem);
					max-height: calc(30vh - 2rem);
					padding: 1rem;
					margin: 0;
					max-width: calc(100vw - 2rem);

					/* Let the parent container handle the overflow */
					overflow: unset;
				}
			}
		}
	}
}
